import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ToggleButton,
  ToggleButtonGroup,
  useTheme,
} from "@mui/material";
import { ButtonHeader } from "../../common/ButtonHeader";
import TextInput from "../TextInput";
import PhoneInput from "../PhoneInput";
import { Form, Formik } from "formik";
import * as Yup from "yup";

const CONSULTANT = "Consultant";
const ENTREPRISE = "Entreprise";
const Contact = ({
  buttonColor,
  textColor,
  endIcon,
  maxWidth,
  height,
  formName,
}: {
  buttonColor?: string;
  textColor?: string;
  endIcon?: React.ReactNode;
  maxWidth?: number;
  height?: number;
  formName?: string;
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const sendEmail = (
    type: string,
    prenom: string,
    nom: string,
    nomResponsable: string,
    nomEntreprise: string,
    email: string,
    tel: string,
    objet: string,
    commentaire: string,
  ) => {
    // Créer l'objet contenant les informations du formulaire
    const formData = {
      type: type,
      prenom: prenom,
      nom: nom,
      nomResponsable: nomResponsable,
      nomEntreprise: nomEntreprise,
      email: email,
      commentaire: commentaire,
      objet,
      cc: email,
    };

    // Créer l'objet contenant les informations de l'e-mail à envoyer
    const emailData = {
      cc: "reda.lattaf@ksportage.fr,mohamed.benoikken@ksportage.fr,portageks@gmail.com", // Destinataires en copie
      subject: "[KS GROUP] formulaire de contact : Prise de contact", // Objet de l'e-mail
      text: JSON.stringify(formData), // Corps de l'e-mail au format texte brut
      html: `
             <h1>Nouveau message de contact</h1>
             <p>Type : ${type}</p>
             ${prenom && `<p>Prénom : ${prenom}</p>`}
             ${nom && `<p>Nom : ${nom}</p>`}
             ${nomResponsable && `<p>Résponsable : ${nomResponsable}</p>`}
             ${nomEntreprise && `<p>Entreprise : ${nomEntreprise}</p>`}
             <p>Email : ${email}</p>
             <p>Téléphone : ${tel}</p>
             <p>objet : ${objet}</p>
             <p>Commentaire : ${commentaire}</p>`, // Corps de l'e-mail au format HTML
    };
    //fetch(`${process.env.REACT_APP_API_URI}/api/contact-us`, {
    fetch(`${process.env.REACT_APP_API_URI}/api/contact-us`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(emailData),
    })
      .then((response) => {
        if (response.ok) {
          // Message de confirmation en cas de succès
          alert("Votre message a bien été envoyé !");
          setOpen(false);
        } else {
          // Message d'erreur en cas d'échec
          alert("Une erreur est survenue, veuillez réessayer plus tard.");
        }
      })
      .catch((error) => {
        // Message d'erreur en cas d'échec
        alert("Une erreur est survenue, veuillez réessayer plus tard.");
        console.error("Erreur lors de l'envoi de l'e-mail :", error);
      });
  };

  const onClickSendEmail = (
    type: string,
    prenom: string,
    nom: string,
    nomResponsable: string,
    nomEntreprise: string,
    email: string,
    phone: string,
    objet: string,
    commentaire: string,
  ) => {
    sendEmail(
      type,
      prenom,
      nom,
      nomResponsable,
      nomEntreprise,
      email,
      phone,
      objet,
      commentaire,
    );
  };
  const theme = useTheme();
  const validationSchema = Yup.object().shape({
    type: Yup.string().required("Obligatoire"),
    // Valider l'adresse e-mail
    email: Yup.string()
      .email("L'email n'est pas valide")
      .required("Obligatoire"),
    // Valider le prénom
    prenom: Yup.string().when("type", ([type], sch) => {
      return type === CONSULTANT
        ? sch.required("Obligatoire")
        : sch.notRequired();
    }),
    // Valider le nom
    nom: Yup.string().when("type", ([type], sch) => {
      return type === CONSULTANT
        ? sch.required("Obligatoire")
        : sch.notRequired();
    }),
    // Valider le nom de l'entreprise
    nomEntreprise: Yup.string().when("type", ([type], sch) => {
      return type === ENTREPRISE
        ? sch.required("Obligatoire")
        : sch.notRequired();
    }),
    // Valider le nom du responsable
    nomResponsable: Yup.string().when("type", ([type], sch) => {
      return type === ENTREPRISE
        ? sch.required("Obligatoire")
        : sch.notRequired();
    }),
    // Valider l'objet
    objet: Yup.string().required("Obligatoire"),
    // Valider le commentaire
    commentaire: Yup.string().required("Obligatoire"),
    // Valider le téléphone, obligatoire et suppérieur à 8 chiffres et peut contenir des espaces et le negatif
    phone: Yup.string()
      .required("Obligatoire")
      // et supp à 8 chiffre
      .matches(/^[- +()0-9]{8,}$/, "Le numéro n'est pas valide"),
  });

  return (
    <React.Fragment>
      <Formik
        initialValues={{
          type: CONSULTANT,
          prenom: "",
          nom: "",
          nomResponsable: "",
          nomEntreprise: "",
          email: "",
          phone: "",
          objet: "",
          commentaire: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          onClickSendEmail(
            values.type,
            values.prenom,
            values.nom,
            values.nomResponsable,
            values.nomEntreprise,
            values.email,
            values.phone,
            values.objet,
            values.commentaire,
          );
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form id={formName} onSubmit={handleSubmit}>
            <ButtonHeader
              maxWidth={maxWidth ?? 150}
              onClick={handleClickOpen}
              removeShadow
              color={buttonColor ?? theme.palette.secondary.main}
              textColor={textColor ?? "#fff"}
              endIcon={endIcon ?? null}
              height={height ?? 44}
            >
              {"Nous contacter"}
            </ButtonHeader>
            <Dialog fullWidth open={open} onClose={handleClose}>
              <DialogTitle>
                <span
                  style={{ fontSize: 25, marginBottom: 10, fontWeight: "bold" }}
                >
                  {"Nous contacter"}
                </span>
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Nous sommes ravis de vous offrir l&apos;opportunité de nous
                  contacter. Que ce soit pour poser une question, partager vos
                  commentaires, ou discuter d&apos;une collaboration
                  potentielle, nous sommes là pour vous écouter.
                </DialogContentText>

                <div
                  style={{ marginBottom: 10, marginTop: 10 }}
                  className="flex flex-col gap-4 mt-4"
                >
                  <span style={{ marginRight: 10 }} className="mr-4">
                    Vous êtes:
                  </span>
                  <ToggleButtonGroup
                    color="primary"
                    exclusive
                    onChange={handleChange("type")}
                    value={values.type}
                    aria-label="Platform"
                  >
                    <ToggleButton id="type" value="Consultant">
                      {CONSULTANT}
                    </ToggleButton>
                    <ToggleButton id="type" value="Entreprise">
                      {ENTREPRISE}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  {values.type === CONSULTANT && (
                    <TextInput
                      placeholder="Prénom*"
                      onChange={handleChange("prenom")}
                      label="Prénom*"
                      type="firstName"
                      name="prenom"
                      error={touched.prenom && Boolean(errors.prenom)}
                      helperText={touched.prenom ? errors.prenom : ""}
                      onBlur={handleBlur("prenom")}
                    />
                  )}
                  {values.type === ENTREPRISE && (
                    <TextInput
                      placeholder="Nom du responsable*"
                      label="Nom du responsable*"
                      onChange={handleChange("nomResponsable")}
                      name="nomResponsable"
                      error={
                        touched.nomResponsable && Boolean(errors.nomResponsable)
                      }
                      helperText={
                        touched.nomResponsable ? errors.nomResponsable : ""
                      }
                      onBlur={handleBlur("nomResponsable")}
                    />
                  )}
                  {values.type === CONSULTANT && (
                    <TextInput
                      placeholder="Nom*"
                      label="Nom*"
                      onChange={handleChange("nom")}
                      name="nom"
                      error={touched.nom && Boolean(errors.nom)}
                      helperText={touched.nom ? errors.nom : ""}
                      onBlur={handleBlur("nom")}
                    />
                  )}
                  {values.type === ENTREPRISE && (
                    <TextInput
                      placeholder="Nom de l'entreprise*"
                      onChange={handleChange("nomEntreprise")}
                      label="Nom de l'entreprise*"
                      name="nomEntreprise"
                      error={
                        touched.nomEntreprise && Boolean(errors.nomEntreprise)
                      }
                      helperText={
                        touched.nomEntreprise ? errors.nomEntreprise : ""
                      }
                      onBlur={handleBlur("nomEntreprise")}
                    />
                  )}

                  <TextInput
                    placeholder="Email Address*"
                    label="Email Address*"
                    onChange={handleChange("email")}
                    type="email"
                    name="email"
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email ? errors.email : ""}
                    onBlur={handleBlur("email")}
                  />

                  <PhoneInput
                    onChange={handleChange("phone")}
                    name="phone"
                    error={touched.phone && Boolean(errors.phone)}
                    helperText={touched.phone ? errors.phone : ""}
                    onBlur={handleBlur}
                    value={values.phone}
                  />

                  <TextInput
                    placeholder="Objet*"
                    label="Objet*"
                    onChange={handleChange}
                    name="objet"
                    error={touched.objet && Boolean(errors.objet)}
                    helperText={touched.objet ? errors.objet : ""}
                    onBlur={handleBlur}
                  />

                  <TextInput
                    multiline
                    rows={4}
                    placeholder="Message*"
                    label="Message*"
                    onChange={handleChange("commentaire")}
                    name="commentaire"
                    error={touched.commentaire && Boolean(errors.commentaire)}
                    helperText={touched.commentaire ? errors.commentaire : ""}
                    onBlur={handleBlur("commentaire")}
                  />
                </div>
              </DialogContent>
              <DialogActions className="mr-4 mb-4">
                <ButtonHeader
                  maxWidth={150}
                  textColor="white"
                  onClick={handleClose}
                >
                  Annuler
                </ButtonHeader>
                <ButtonHeader
                  form={formName}
                  maxWidth={150}
                  textColor="white"
                  type="submit"
                >
                  Envoyer
                </ButtonHeader>
              </DialogActions>
            </Dialog>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default Contact;
