import { Button } from "@mui/material";
import Icon from "@mui/material/Icon";

import { useHistory } from "react-router-dom";

export const ButtonHeader = ({
  color,
  muiBaseColor,
  children,
  onClick,
  disabled,
  maxWidth,
  removeShadow,
  textColor,
  size,
  height,
  endIcon,
  to,
  endIconSize,
  type,
  form,
}: {
  color?: string;
  muiBaseColor?:
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "warning"
    | "info";

  fixedWidth?: boolean;
  children: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  maxWidth?: number;
  removeShadow?: boolean;
  textColor?: string;
  size?: "small" | "medium" | "large";
  height?: number;
  endIcon?: React.ReactNode;
  to?: string;
  endIconSize?: number;
  type?: "button" | "submit" | "reset";
  form?: string;
}) => {
  const hoverColor = color && darkenColor(color, 20); // Calculate hover color
  const textColorContrasted = color && getTextColor(color); // Calculate text color
  const history = useHistory();
  const onClickHandler = () => {
    if (to) {
      history.push(to);
    }
    if (onClick) {
      onClick();
    }
  };

  return (
    <Button
      onClick={onClickHandler}
      fullWidth
      size={size ?? "large"}
      sx={{
        borderRadius: 2,
        height: height ?? 44,
        fontSize: 16,
        backgroundColor: color, // Main background color
        "&:hover": {
          backgroundColor: hoverColor, // Background color on hover
        },
        // Text color
        maxWidth: maxWidth,
        boxShadow: removeShadow ? "none" : "default",
        whiteSpace: "nowrap",
      }}
      color={muiBaseColor ? muiBaseColor : "primary"}
      variant="contained"
      disabled={disabled}
      endIcon={
        endIcon && (
          <Icon
            style={{ fontSize: endIconSize ?? 22 }}
            sx={{ color: textColor ?? textColorContrasted }}
          >
            {endIcon}
          </Icon>
        )
      }
      type={type}
      form={form}
    >
      <span
        style={{ color: textColor ?? textColorContrasted, fontWeight: "bold" }}
      >
        {children}
      </span>
    </Button>
  );
};
// Function to calculate darker shade of a color
const darkenColor = (col: string, percent: number) => {
  const num = parseInt(col.slice(1), 16);
  const amt = Math.round(2.55 * percent);
  const R = (num >> 16) + amt;
  const G = ((num >> 8) & 0x00ff) + amt;
  const B = (num & 0x0000ff) + amt;
  return (
    "#" +
    (
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
      (B < 255 ? (B < 1 ? 0 : B) : 255)
    )
      .toString(16)
      .slice(1)
  );
};

// Function to calculate text color based on background brightness
const getTextColor = (bgColor: string) => {
  const threshold = 130; // Threshold for determining light or dark background
  const rgb = parseInt(bgColor.substring(1), 16);
  const r = (rgb >> 16) & 0xff;
  const g = (rgb >> 8) & 0xff;
  const b = (rgb >> 0) & 0xff;
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness > threshold ? "#000000" : "#ffffff"; // Use black or white text depending on brightness
};
