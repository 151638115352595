import { BrowserRouter } from "react-router-dom";
import React from "react";
import { createRoot } from "react-dom/client";
import { I18nextProvider } from "react-i18next";

import Router from "./router";
import i18n from "./translation";
import "./index.css";
import { Provider, useSelector } from "react-redux";
import { store } from "./store";

import themes from "./themes";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";

const root = createRoot(document.getElementById("root") as HTMLElement);
const App = () => {
  const customization = useSelector((state: any) => state.customization);
  return (
    <ThemeProvider theme={themes(customization)}>
      <CssBaseline />
      <BrowserRouter>
        <I18nextProvider i18n={i18n}>
          <StyledEngineProvider injectFirst>
            <Router />
          </StyledEngineProvider>
        </I18nextProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
};
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
);
