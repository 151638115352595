import * as React from "react";

import { MuiTelInput } from "mui-tel-input";
const PhoneInput = ({
  value,
  onChange,
  error,
  helperText,
  onBlur,
  name,
}: {
  value?: string;
  onChange: ((value: string) => void) | undefined;
  error?: boolean;
  helperText?: string;
  name?: string;
  onBlur?: {
    (e: React.FocusEvent<unknown, Element>): void;
    <T = unknown>(
      fieldOrEvent: T,
    ): T extends string ? (e: unknown) => void : void;
  };
}) => {
  return (
    <div className="flex flex-col gap-2 w-full max-w-[240px]">
      <div>
        <span className="whitespace-nowrap truncate">Numéro de téléphone*</span>
      </div>
      <MuiTelInput
        id="phone"
        fullWidth
        value={value as string}
        defaultCountry="FR"
        onChange={onChange}
        placeholder="Numéro de téléphone*"
        onWheel={(e) => (e.target as HTMLElement).blur()}
        inputProps={{
          // no border when focused
          style: { boxShadow: "none" },
        }}
        sx={{ border: "none" }}
        error={error}
        helperText={helperText}
        onBlur={onBlur}
        name={name}
      />
    </div>
  );
};
export default PhoneInput;
