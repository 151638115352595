import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import { FooterSection, NavLink, LogoContainer } from "./styles";
import { useTheme } from "@mui/material";
import { EmailOutlined, PhoneOutlined } from "@mui/icons-material";
import React from "react";
import { useHistory } from "react-router-dom";

interface SocialLinkProps {
  href: string;
  src: string;
}

const footerMenu = [
  {
    title: "En savoir plus",
    links: [
      {
        title: "À propos",
        link: "/#intro",
      },
      {
        title: "Portage salarial",
        link: "/portage-salarial",
      },
      {
        title: "Simulation en portage salarial",
        link: "/simulation",
      },
      {
        title: "Tarifs",
        link: "/#pricing",
      },
      {
        title: "Programme de parrainage",
      },
    ],
  },
  {
    title: "Resources",
    links: [
      {
        title: "FAQ",
      },
      {
        title: "Cooptation",
      },
      {
        title: "Partenaires",
      },
      {
        title: "Nos guides portage salarial",
      },
      {
        title: "Nous recrutons",
      },
      {
        title: "Missions",
      },
    ],
  },
];

const Footer = () => {
  const history = useHistory();
  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  const theme = useTheme();

  const ContactShip = ({
    color,
    text,
    icon,
    textColor,
    maxWidth,
    href,
  }: {
    color: string;
    text: string;
    icon: React.ReactNode;
    textColor?: string;
    maxWidth?: number;
    href?: string;
  }) => (
    <a
      href={href}
      style={{ backgroundColor: color, maxWidth: `${maxWidth ?? 200}px` }}
      className={`flex flex-row items-center gap-2 rounded-full h-10 px-4`}
    >
      <span
        className={`text-${
          textColor ?? "white"
        } leading-10 flex items-center gap-2`}
      >
        {icon}
        {text}
      </span>
    </a>
  );

  return (
    <>
      <FooterSection
        style={{
          backgroundImage: "url(/img/png/bg_logo.png)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom right",
          backgroundSize: "460px",
        }}
      >
        <Container>
          <div className="flex justify-between flex-wrap gap-2">
            <div className="w-60 flex flex-col gap-2 max-[600px]:mx-auto">
              <NavLink to="/">
                <LogoContainer>
                  <SvgIcon
                    src="ks.svg"
                    aria-label="homepage"
                    width="150px"
                    height="60px"
                  />
                </LogoContainer>
              </NavLink>
              <span>
                SASU au capital de 310 000,00 € <br />
                Siège social : 9 RUE DES COLONNES, 75002 PARIS <br />
                SIRET : 98262060100012 <br /> RCS : 982 620 601 <br /> N° TVA :
                FR96982620601 <br />
                CODE APE : 8299Z
              </span>
              <ContactShip
                text="+33 7 43 39 35 85"
                color={theme.palette.primary.main}
                icon={<PhoneOutlined />}
                href="tel:+33743393585"
              />
              <ContactShip
                text="gestion@ksportage.fr"
                color={theme.palette.secondary.main}
                icon={<EmailOutlined />}
                href="mailto:gestion@ksportage.fr"
              />
              <ContactShip
                text="Fr"
                color="white"
                textColor="black"
                icon={
                  <img
                    src={`/img/png/france_icon.png`}
                    alt="france_icon"
                    width={25}
                    height={25}
                  />
                }
                maxWidth={90}
              />
            </div>
            {footerMenu.map((menu, index) => (
              <div
                key={`menu-${index}`}
                className="w-60  flex flex-col gap-4 max-[600px]:mx-auto"
              >
                <span className="text-2xl font-[Gilroy-Bold]">
                  {menu.title}
                </span>
                {menu.links.map((link, linkIndex) => (
                  <span
                    onClick={() => (link.link ? history.push(link.link) : null)}
                    className="cursor-pointer"
                    key={`link-${index}-${linkIndex}`}
                  >
                    {link.title}
                  </span>
                ))}
              </div>
            ))}

            <div className="max-[600px]:w-60 flex flex-col gap-4 max-[600px]:mx-auto">
              <span className="text-2xl font-[Gilroy-Bold]">Suivez-nous</span>
              <div className="flex flex-row gap-4 justify-arround">
                <SocialLink
                  href="https://www.linkedin.com/company/ks-portage/about/?viewAsMember=true"
                  src="linkedin.svg"
                />
                <SocialLink
                  href="https://www.facebook.com/"
                  src="facebook.svg"
                />
              </div>
              <div className="flex flex-row gap-4">
                <SocialLink
                  href="https://www.instagram.com/"
                  src="instagram.svg"
                />
                <SocialLink href="https://www.twitter.com/" src="twitter.svg" />
              </div>
            </div>
          </div>
        </Container>
      </FooterSection>
    </>
  );
};

export default withTranslation()(Footer);
