import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  CustomNavLinkSmall,
  Span,
} from "./styles";
import { ButtonHeader } from "../../common/ButtonHeader";
import { Link } from "react-router-dom";
import { HeaderButton } from "../../common/HeaderButton";
import Contact from "../ContactUs";
import HeaderContainer from "../../common/HeaderContainer";
import Close from "@mui/icons-material/Close";
import { Drawer, IconButton } from "@mui/material";
import { Menu } from "@mui/icons-material";

const Header = ({ t }: any) => {
  const [visible, setVisibility] = useState(false);

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    return (
      <>
        <CustomNavLinkSmall>
          <Span>
            <CustomNavLinkSmall>
              <Link to={"/#intro"}>
                <HeaderButton>{t("Product")}</HeaderButton>
              </Link>
            </CustomNavLinkSmall>
          </Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall>
          <Span>
            <Link to={"/portage-salarial"}>
              <HeaderButton>{t("About")}</HeaderButton>
            </Link>
          </Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall>
          <Span>
            <Link to={"/simulation"}>
              <HeaderButton>{t("Portage")}</HeaderButton>
            </Link>
          </Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall>
          <Span>
            <CustomNavLinkSmall>
              <Link to={"/#pricing"}>
                <HeaderButton>{t("Mission")}</HeaderButton>
              </Link>
            </CustomNavLinkSmall>
          </Span>
        </CustomNavLinkSmall>
      </>
    );
  };

  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY || window.pageYOffset);
    };

    // Attach the event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty dependency array means this effect runs only once, similar to componentDidMount

  return (
    <HeaderSection
      className={scrollPosition === 0 ? "transparent top-10" : "white"}
    >
      <HeaderContainer>
        <div className="flex justify-between items-center">
          <LogoContainer to="/" aria-label="homepage">
            <SvgIcon src="ks.svg" width="150px" height="64px" />
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <NotHidden>
            <div className="flex gap-2">
              <ButtonHeader
                removeShadow
                maxWidth={150}
                color="#2a1f50"
                onClick={() => null}
                height={60}
              >
                {t("Login")}
              </ButtonHeader>
              <Contact formName="mobileFormName" height={60} />
            </div>
          </NotHidden>

          <Burger onClick={showDrawer}>
            <Menu fontSize="large" />
          </Burger>
        </div>
        <Drawer open={visible} anchor="right" onClose={onClose}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "1rem",
              gap: "1rem",
            }}
          >
            <LogoContainer to="/" aria-label="homepage">
              <SvgIcon src="ks.svg" width="100px" height="64px" />
            </LogoContainer>
            <IconButton className="flex" onClick={onClose}>
              <Close />
            </IconButton>
          </div>
          <MenuItem />
          <div className="mt-4 flex flex-col gap-4 justify-center items-center">
            <div
              style={{
                display: "flex",
                gap: 8,
                flexDirection: "column",
                alignItems: "center",
              }}
              className="flex"
            >
              <ButtonHeader
                removeShadow
                maxWidth={150}
                color="#2a1f50"
                onClick={() => null}
              >
                {t("Login")}
              </ButtonHeader>
              <Contact formName="desktopFormName" />
            </div>
          </div>
        </Drawer>
      </HeaderContainer>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
