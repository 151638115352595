const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/portage-salarial"],
    exact: true,
    component: "PortageSalarial",
  },
  {
    path: ["/simulation"],
    exact: true,
    component: "Simulation",
  },
];

export default routes;
