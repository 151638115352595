import { ButtonProps } from "../types";

export const HeaderButton = ({ children }: ButtonProps) => {
  return (
    <div className="inline-block group cursor-pointer">
      <span className={`group-hover:text-[#73a7f4]`}>{children}</span>
      <span
        className={`block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-[#73a7f4]`}
      ></span>
    </div>
  );
};
