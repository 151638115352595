import * as React from "react";
import PropTypes from "prop-types";

import { InputAdornment, TextField } from "@mui/material";
const TextInput = ({
  value,
  onChange,
  placeholder,
  label,
  multiline,
  rows,
  type,
  endAdornmentIcon,
  error,
  helperText,
  onBlur,
  name,
}: {
  value?: string;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  placeholder?: string;
  label?: string;
  multiline?: boolean;
  rows?: number;
  type?: string;
  endAdornmentIcon?: string;
  error?: boolean;
  helperText?: string;
  name?: string;
  onBlur?: {
    (e: React.FocusEvent<unknown, Element>): void;
    <T = unknown>(
      fieldOrEvent: T,
    ): T extends string ? (e: unknown) => void : void;
  };
}) => {
  return (
    <div className="flex flex-col gap-2 w-full max-w-[240px]">
      <div>
        <span className="whitespace-nowrap truncate">{label}</span>
      </div>
      <TextField
        fullWidth
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        onWheel={(e) => (e.target as HTMLElement).blur()}
        multiline={multiline}
        rows={rows}
        type={type}
        InputProps={{
          endAdornment: endAdornmentIcon && (
            <InputAdornment position="end">
              <span style={{ zIndex: "10" }} className="font-[Gilroy-Bold]">
                {endAdornmentIcon}
              </span>
            </InputAdornment>
          ),
        }}
        error={error}
        helperText={helperText}
        onBlur={onBlur}
        name={name}
      />
    </div>
  );
};
TextInput.propTypes = {
  value: PropTypes.number,
};
export default TextInput;
